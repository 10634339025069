<template>
  <div class="login-page">
    <div class="login-page-form">
      <div class="login-page-form-title">登 录</div>
      <a-form
        ref="formRef"
        :model="loginForm"
        :rules="loginRules"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
      >
        <a-form-item label="手机号" name="phoneNumber">
          <a-input v-model:value="loginForm.phoneNumber"></a-input>
        </a-form-item>
        <a-form-item label="密码" name="password">
          <a-input v-model:value="loginForm.password" type="password"></a-input>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 19, offset: 5 }">
          <a-button type="primary" :loading="loading" @click="toLogin"
            >登录</a-button
          >
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
<script>
import useLogin from '@/js/useLogin'
export default {
  setup () {
    const { formRef, loading, loginForm, loginRules, toLogin } = useLogin()
    return {
      formRef,
      loading,
      loginForm,
      loginRules,
      toLogin
    }
  }
}
</script>
<style lang="scss" scoped>
.login-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  &-form {
    width: 350px;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 5px #ddd;
    transition: all 0.3s;
    &:hover {
      box-shadow: 0 0 15px #ddd;
    }
    &-title {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      color: #007aff;
      margin-bottom: 10px;
    }
  }
}
</style>
